import React from 'react';

import Header from './components/Header';
import HomePage from './components/HomePage'
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <div className="d-flex flex-column min-vh-100">
        <Header/>
        <HomePage/>
        <Footer/>
      </div>
    </div>
  );
}

export default App;
