import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import './index.css';
import App from './App';
import CV from './assets/cv.pdf';
import * as serviceWorker from './serviceWorker';
import SDI from './papers/SDI/sdi';

import './custom.scss';
import "bootstrap-icons/font/bootstrap-icons.css";

ReactDOM.render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<App />} />
            <Route path="/resume" element={<Navigate to={CV} replace />} />
            <Route path="/sdi" element={<SDI />} />
        </Routes>
    </BrowserRouter>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
