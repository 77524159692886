import React, { useState } from "react";

export default function CitationBlock() {
    const [showButton, setShowButton] = useState(false);
    return (
        <div className="position-relative" onMouseEnter={() => setShowButton(true)} onMouseLeave={() => setShowButton(false)}>
            <pre className="bg-body-secondary"><code>{`
                @misc{lukoianov2024score,
                    title={Score Distillation via Reparametrized DDIM}, 
                    author={Artem Lukoianov and Haitz Sáez de Ocáriz Borde and
                            Kristjan Greenewald and Vitor Campagnolo Guizilini and Timur Bagautdinov and
                            Vincent Sitzmann and Justin Solomon},
                    year={2024},
                    eprint={2405.15891},
                    archivePrefix={arXiv},
                    primaryClass={cs.CV}
                }
            `}
            </code></pre>
            {showButton && (
                <button 
                    className="btn btn-sm btn-secondary position-absolute top-0 end-0 m-2"
                    onClick={() => {
                    navigator.clipboard.writeText(`
                        @misc{lukoianov2024score,
                            title={Score Distillation via Reparametrized DDIM}, 
                            author={Artem Lukoianov and Haitz Sáez de Ocáriz Borde and
                                Kristjan Greenewald and Vitor Campagnolo Guizilini and Timur Bagautdinov and
                                Vincent Sitzmann and Justin Solomon},
                            year={2024},
                            eprint={2405.15891},
                            archivePrefix={arXiv},
                            primaryClass={cs.CV}
                    }`);
                    }}
                >
                    <i className="bi bi-clipboard"></i>
                </button>
            )}
        </div>
    )
  }