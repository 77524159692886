import React from 'react';
import { Helmet } from 'react-helmet';
// import MethodDiagram from './assets/system.svg';
// import StaticResults from './assets/main_ours_results.svg';
import CitationBlock from './components/citing';
import SDILinks from './components/links';
import ScrollBar from './components/scroll_bar';

import ninja_gif from './assets/examples/a_photograph_of_a_ninja.gif';
import zombie_gif from './assets/examples/Pumpkin_head_zombie_skinny_highly_detailed_photorealistic.gif';
import bread_gif from './assets/examples/A_DSLR_photo_of_a_freshly_baked_round_loaf_of_sourdough_bread.gif';
import knight_gif from './assets/examples/a_photograph_of_a_knight.gif';
import strawberry_gif from './assets/examples/A_ripe_strawberry.gif';
import icecream_gif from './assets/examples/An_ice_cream_sundae.gif';
// import michdog_gif from './assets/examples/Michelangelo_style_statue_of_dog_reading_news_on_a_cellphone.gif';
import robobee_gif from './assets/examples/Robotic_bee_high_detail.gif';

import sd_main_principle_gif from './assets/illustrations/sd_main_principle.gif';
import reparametrization_gif from './assets/illustrations/reparametrization.gif';

import sd_formula_svg from './assets/formulas/kappa_sds.svg';
import kappa_sdi_svg from './assets/formulas/kappa_sdi.svg';
import stationary_svg from './assets/formulas/kappa_stationary.svg';
import sds_mixing_trajectories from './assets/illustrations/sds_mixing_trajectories.gif';
import sdi_ddim_inversion from './assets/illustrations/sdi_ddim_inversion.gif';
import sdi_trajectories from './assets/illustrations/sdi_trajectories.gif';

function SDIgifExamples() {
  return (
    <div className="row justify-content-end text-end">
      <div className="col-12 col-md-4 my-2">
        <img src={bread_gif} className="img-fluid" alt="A DSLR photograph of a freshly baked round loaf of sourdough bread"/>
        <p className="fst-italic text-muted">&ldquo;A DSLR photograph of a freshly baked round loaf of sourdough bread&rdquo;</p>
      </div>
      {/* <div className="col-10 col-md-5 my-2">
        <img src={ninja_gif} className="img-fluid" alt="A photograph of a ninja" />
      </div> */}
      {/* <div className="col-10 col-md-5 my-2">
        <img src={michdog_gif} className="img-fluid" alt="Michelangelo style statue of dog reading news on a cellphone"/>
      </div> */}
      <div className="col-12 col-md-4 my-2">
        <img src={robobee_gif} className="img-fluid" alt="Robotic bee high detail" />
        <p className="fst-italic text-muted">&ldquo;Robotic bee high detail&rdquo;</p>
      </div>
      <div className="col-12 col-md-4 my-2">
        <img src={zombie_gif} className="img-fluid" alt="Pumpkin head zombie, skinny, highly detailed" />
        <p className="fst-italic text-muted">&ldquo;Pumpkin head zombie, skinny, highly detailed&rdquo;</p>
      </div>
      <div className="col-12 col-md-4 my-2">
        <img src={strawberry_gif} className="img-fluid" alt="A ripe strawberry" />
        <p className="fst-italic text-muted">&ldquo;A ripe strawberry&rdquo;</p>
      </div>
      <div className="col-12 col-md-4 my-2">
        <img src={icecream_gif} className="img-fluid" alt="An ice cream sundae" />
        <p className="fst-italic text-muted">&ldquo;An ice cream sundae&rdquo;</p>
      </div>
      <div className="col-12 col-md-4 my-2">
        <img src={knight_gif} className="img-fluid" alt="A photograph of a knight" />
        <p className="fst-italic text-muted">&ldquo;A photograph of a knight&rdquo;</p>
      </div>
    </div>
  )
}

function SDIHeader() {
  return (
    <header className="sticky-top">
    <nav className="navbar px-4 navbar-expand-md navbar-light bg-light">
      <div className="container-xl text-dark fs-5">

          <div className="me-auto">
              <h4><span  role="img" aria-label="Emoji's about the project"> 🖼️🔗🛠️🌐 </span></h4>
          </div>

          <div className="d-none fw-lighter d-md-block">Score Distillation via Reparametrized DDIM</div>
  
          <div className="ms-auto">
              <div className="collapse navbar-collapse" id="navbarNavDropdown">
                  <ul className="navbar-nav">
                      <li className="nav-item">
                          <a className="nav-link active fw-light" href="/#">More Research</a>
                      </li>
                  </ul>
              </div>
          </div>

          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
          </button>

      </div>
    </nav>

  </header>
  );
}

function SDI() {
  return (
    <div className="App">
        <Helmet>
          <title>Score Distillation via Reparametrized DDIM</title>
          <link rel="icon" type="image/png" href="/favicons/pages/sdi.png" />
        </Helmet>
        <SDIHeader/>
        <ScrollBar />
        <section id="cdi-main-page">
        <div className="container">
            <div className="row my-4">
                <div className="col-12 col-md-12">
                  <h1 className="mx-auto fw-bold text-start">
                    Score Distillation via Reparametrized DDIM
                  </h1>
                </div>
                <div className="col-4 col-md-4 text-start text-secondary">
                    <h4 className="fw-bold">NeurIPS 2024</h4>
                </div>
                <div className="col-12 col-md-10 ">
                  <h5 className="text-start">
                    <span><a className="active text-decoration-none" href="/#">Artem&nbsp;Lukoianov</a><sup>1</sup>, </span>
                    <span><a className="active text-decoration-none" href="https://scholar.google.com/citations?user=aP0OakUAAAAJ&hl=en">Haitz&nbsp;Sáez&nbsp;de&nbsp;Ocáriz&nbsp;Borde</a><sup>2</sup>, </span>
                    <span><a className="active text-decoration-none" href="https://kgreenewald.github.io">Kristjan&nbsp;Greenewald</a><sup>3</sup>, </span>
                    <span><a className="active text-decoration-none" href="https://scholar.google.com.br/citations?user=ow3r9ogAAAAJ&hl=en">Vitor&nbsp;Campagnolo&nbsp;Guizilini</a><sup>4</sup>, </span>
                    <span><a className="active text-decoration-none" href="https://scholar.google.ch/citations?user=oLi7xJ0AAAAJ&hl=en">Timur&nbsp;Bagautdinov</a><sup>5</sup>, </span>
                    <span><a className="active text-decoration-none" href="https://www.vincentsitzmann.com">Vincent&nbsp;Sitzmann</a><sup>1</sup>, </span>
                    <span><a className="active text-decoration-none" href="https://people.csail.mit.edu/jsolomon/">Justin&nbsp;Solomon</a><sup>1</sup></span>
                  </h5>
                </div>
                <div className="col-8 col-md-8 text-start">
                  <span className="author-block"><sup>1</sup>Massachusetts&nbsp;Institute&nbsp;of&nbsp;Technology, </span>
                  <span className="author-block"><sup>2</sup>University&nbsp;of&nbsp;Oxford, </span>
                  <span className="author-block"><sup>3</sup>MIT‑IBM&nbsp;Watson&nbsp;AI&nbsp;Lab, </span>
                  <span className="author-block"><sup>4</sup>Toyota&nbsp;Research&nbsp;Institute, </span>
                  <span className="author-block"><sup>5</sup>Meta&nbsp;Reality&nbsp;Labs&nbsp;Research </span>
                </div>
                <SDILinks/>
            </div>

            <div className="row text-start">
              <div className="col-12">
                <div className="alert alert-warning" role="alert">
                  <strong>Key insight:</strong> Score Distillation is DDIM with a change of variable.
                </div>
              </div>
            </div>

            <div className="row mb-5 mt-3 text-start">
              <h3 className="fw-semibold">TL;DR</h3>
              <p> Skip to 
                    <a className="text-decoration-none" href="#background" onClick={(e) => { e.preventDefault(); const element = document.getElementById('background'); if (element) { const headerHeight = document.querySelector('header')?.offsetHeight || 0; const elementPosition = element.getBoundingClientRect().top + window.pageYOffset - headerHeight; window.scrollTo({top: elementPosition, behavior: 'smooth'}); } }}> Background </a>
                    &rarr;
                    <a className="text-decoration-none" href="#main-idea" onClick={(e) => { e.preventDefault(); const element = document.getElementById('main-idea'); if (element) { const headerHeight = document.querySelector('header')?.offsetHeight || 0; const elementPosition = element.getBoundingClientRect().top + window.pageYOffset - headerHeight; window.scrollTo({top: elementPosition, behavior: 'smooth'}); } }}> Main idea </a>
                    &rarr;
                    <a className="text-decoration-none" href="#fix" onClick={(e) => { e.preventDefault(); const element = document.getElementById('fix'); if (element) { const headerHeight = document.querySelector('header')?.offsetHeight || 0; const elementPosition = element.getBoundingClientRect().top + window.pageYOffset - headerHeight; window.scrollTo({top: elementPosition, behavior: 'smooth'}); } }}> Fix </a>
                    &rarr;
                    <a className="text-decoration-none" href="#results" onClick={(e) => { e.preventDefault(); const element = document.getElementById('results'); if (element) { const headerHeight = document.querySelector('header')?.offsetHeight || 0; const elementPosition = element.getBoundingClientRect().top + window.pageYOffset - headerHeight; window.scrollTo({top: elementPosition, behavior: 'smooth'}); } }}> Results </a>
                    &rarr;
                    <a className="text-decoration-none" href="#citing" onClick={(e) => { e.preventDefault(); const element = document.getElementById('citing'); if (element) { const headerHeight = document.querySelector('header')?.offsetHeight || 0; const elementPosition = element.getBoundingClientRect().top + window.pageYOffset - headerHeight; window.scrollTo({top: elementPosition, behavior: 'smooth'}); } }}> Citing </a>
              </p>
              <div className="col-12 col-md-8 my-2">
                  <p>
                    Score Distillation Sampling (SDS) is a promising technique that allows to use pre-trained 2D diffusion models for 3D generation.
                    However, the quality of the generated 3D assets is limited.
                  </p>
                  <p> In this work we:
                    <ul style={{ listStyleType: 'none', paddingLeft: 20 }}>
                      <li>🔎 Theoretically show that SDS ≈ 2D Diffusion</li>
                      <li>🚨 Reveal that the noise term in SDS is the reason for over-smoothing</li>
                      <li>🛠️ Suggest a fix </li>
                      <li>✅ Improve the quality of 3D generation</li>
                    </ul>
                  </p>
              </div>
              <div className="col-12 col-md-4 my-2 text-center">
                <div className="mt-5">
                  <img src={ninja_gif} className="img-fluid" alt="A photograph of a ninja" />
                  <p className="fst-italic text-muted">&ldquo;A photograph of a ninja&rdquo;</p>
                </div>
              </div>
            </div>

            <div className="row my-5 justify-content-center">
              <div className="col-12 col-md-6 my-2 order-md-1">
                <img src={sd_main_principle_gif} className="img-fluid" alt="Main principle of Score Distillation" />
              </div>
              <div className="col-12 col-md-6 my-2 order-md-2">
                  <h3 id="background" className="fw-semibold">How does Score Distillation work?</h3>
                  <p>
                    Proposed in <a href="https://dreamfusion3d.github.io/" className="text-decoration-none">DreamFusion </a>
                            and <a href="https://pals.ttic.edu/p/score-jacobian-chaining" className="text-decoration-none">Score Jacobian Chaining</a>
                    , Score Distillation is a method for generating 3D shapes using a pre-trained and frozen 2D diffusion model.
                  </p>
                  <ol className="text-start">
                    <li>Initialize a differentiable 3D representation </li>
                    <li style={{marginLeft: '20px'}}>Sample a random camera pose 📷</li>
                    <li style={{marginLeft: '20px'}}>Render a view of the object 🖼️</li>
                    <li style={{marginLeft: '20px'}}>Add noise to the rendering 🌫️</li>
                    <li style={{marginLeft: '20px'}}>Denoise the image with the 2D diffusion model </li>
                    <li style={{marginLeft: '20px'}}>Optimize the parameters of the 3D representation to match the denoised image 🎯</li>
                    <li>Repeat steps 2-6 until convergence <span role="img" aria-label="repeat">🔁</span></li>
                  </ol>
                  <p className="fw-bold">
                    Often the generated shapes are over-smoothed and over-saturated.
                  </p>
              </div>
            </div>

            <div id="main-idea" className="row my-5 text-start">
              <div className="col-12 col-md-6 my-2">
                  <h3 className="fw-semibold">What do we propose?</h3>
                  <p>
                      In this work we start with the first principles of image generation with diffusion models.
                      At first, we consider the
                        <a  style={{color: 'red'}}
                            href="https://arxiv.org/abs/2010.02502"
                            className="text-decoration-none"> steps of DDIM </a>
                      that gradually remove noise from the image.
                      Each update can be seen as denoising the image all the way with a
                        <span style={{color: 'blue'}}> single-step update </span>
                      and then adding
                        <span style={{color: 'deeppink'}}> portion of this noise </span>
                      back to the image.

                      By reshuffling the order of the updates, we can define a
                       <span style={{color: 'purple'}}> dual process </span>
                      defined on the space of noise-free images.
                  </p>
                  <p>
                      For a formal derivation, <a href="https://arxiv.org/abs/2405.15891" className="text-decoration-none"> see the full paper </a>.
                  </p>
              </div>
              <div className="col-12 col-md-6 my-2">
                <img src={reparametrization_gif} className="img-fluid" alt="Pumpkin head zombie, skinny, highly detailed" />
              </div>
            </div>

            <div id="fix" className="my-5 text-start">
              <div className="text-start">
                <h3 className="fw-semibold">Good noise is all you need</h3>
                <p>
                  Depending on the choice of the
                    <span style={{color: 'deeppink'}}> noise term </span>
                  the
                    <span style={{color: 'purple'}}> dual process </span>
                  becomes either identical to Score Distillation or to DDIM.
                </p>
              </div>
              <div className="row my-2">
                <div className="col-12 col-md-4 order-md-1">
                    <p> The dual process <b> is equivalend to SDS </b> when noise is sampled randomly: </p>
                </div>
                <div className="col-12 col-md-4 order-md-2">
                    <img src={sd_formula_svg} className="img-fluid" style={{width: '95%'}} alt="Kappa in SDS" />
                </div>
                <div className="col-12 col-md-4 order-md-3 mt-3">
                    <p> Thus each SDS udpate step corresponds to a different DDIM trajectory. This inconsistency averages the final result across multiple trajectories and leads to blurriness. </p>
                </div>
                <div className="col-12 col-md-4 order-md-4">
                    <img src={sds_mixing_trajectories} className="img-fluid" alt="SDS mixing trajectories" />
                </div>

                <div className="col-12 col-md-4 order-md-1">
                    <p>From the reparametrization intuition we know that noise term should follow specific structure:</p>
                </div>
                <div className="col-12 col-md-4 order-md-2">
                    <img src={stationary_svg} className="img-fluid" style={{width: '95%'}} alt="Kappa in DDIM" />
                </div>
                <div className="col-12 col-md-4 order-md-3 mt-3">
                    <p> In practise it is <b>hard to solve</b> as it involves an inverse of the trained diffusion model. We suggest to use DDIM inversion to find an approximate solution.</p>
                </div>
                <div className="col-12 col-md-4 order-md-4">
                    <img src={sdi_ddim_inversion} className="img-fluid" alt="SDS mixing trajectories" />
                </div>

                <div className="col-12 col-md-4 order-md-1">
                    <p>We obtain the noise term with DDIM inversion conditioned on the current rendering:</p>
                </div>
                <div className="col-12 col-md-4 order-md-2">
                    <img src={kappa_sdi_svg} className="img-fluid h-30" style={{width: '95%'}} alt="Kappa in ours" />
                </div>
                <div className="col-12 col-md-4 order-md-3 mt-3">
                    <p>This anchors score distillation process to DDIM trajectories that are consistent with each other both in time and across the different rendering angles.</p>
                </div>
                <div className="col-12 col-md-4 order-md-4">
                    <img src={sdi_trajectories} className="img-fluid" alt="SDS mixing trajectories" />
                </div>
              </div>
            </div>

            <div className="my-5"></div>
            
            <div id="results" className='row my-5 text-end'>
              <h3 className="fw-semibold my-3">Results</h3>
              <SDIgifExamples/>
              <p className="text-start mb-4">
                See <a href="https://arxiv.org/abs/2405.15891" className="text-decoration-none">the paper</a> for detailed comparisons with ProlificDreamer, Noise-Free SD, HiFA, Lucid Dreamer, and other amazing works in Score Distillation.
              </p>
            </div>
            
            <div id="citing" className="row my-2 my-2">
              <div className="col-12 text-start">
                <h3 className="fw-semibold">BibTeX</h3>
                <p> If you find this work useful, please consider citing: </p>
                  <CitationBlock/>
              </div>
            </div>
            <div className="row mt-2 mb-4">
              <div className="col-12 text-center pt-6 pb-6">
                  For any questions, please contact <a href="mailto:arteml@mit.edu">arteml@mit.edu</a>.
              </div>
            </div>

            </div>
        </section>
        {/* <SDIFooter/> */}
    </div>
  );
}

export default SDI;