import React, { useState, useEffect, useMemo } from 'react';

export default function ScrollBar() {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [passedSections, setPassedSections] = useState<string[]>([]);

    const sections = useMemo(() => ['top', 'background', 'main-idea', 'fix', 'results', 'citing'], []);

    useEffect(() => {
        const handleScroll = () => {
            const position = window.scrollY;
            const maxScroll = document.documentElement.scrollHeight - window.innerHeight;
            const scrollPercentage = (position / maxScroll) * 100;
            setScrollPosition(scrollPercentage);

            // Determine which sections have been passed
            // const passed = sections.filter(section => {
            //     if (section === 'top') return true;
            //     const element = document.getElementById(section);
            //     if (element) {
            //         const headerHeight = document.querySelector('header')?.offsetHeight || 0;
            //         const elementPosition = element.getBoundingClientRect().top + window.scrollY - headerHeight;
            //         return position >= elementPosition;
            //     }
            //     return false;
            // });

            const passed = sections.filter((section, sectionIndex) => {
                return scrollPercentage >= (sectionIndex * 100) / (sections.length - 1);
            });
            setPassedSections(passed);
            
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [sections]);

    const handleScrollBarClick = (e: React.MouseEvent<HTMLDivElement>) => {
        const bar = e.currentTarget;
        const rect = bar.getBoundingClientRect();
        const clickPosition = (e.clientY - rect.top) / rect.height;
        const targetScroll = clickPosition * (document.documentElement.scrollHeight - window.innerHeight);
        window.scrollTo({ top: targetScroll, behavior: 'smooth' });
    };

    return (
        <div className="scroll-bar d-none d-md-flex" onClick={handleScrollBarClick}>
            <div className="scroll-line">
                <div 
                    className="scroll-indicator" 
                    style={{ height: `${scrollPosition}%` }}
                ></div>
            </div>
            {sections.map((section, index) => (
                <div
                    key={section}
                    className="scroll-item"
                    onClick={(e) => {
                        e.stopPropagation();
                        if (section === 'top') {
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                        } else {
                            const element = document.getElementById(section);
                            if (element) {
                                const headerHeight = document.querySelector('header')?.offsetHeight || 0;
                                const elementPosition = element.getBoundingClientRect().top + window.pageYOffset - headerHeight;
                                window.scrollTo({ top: elementPosition, behavior: 'smooth' });
                            }
                        }
                    }}
                    style={{ top: `${(index / (sections.length - 1)) * 100}%` }}
                >
                    <div className={`scroll-circle ${passedSections.includes(section) ? 'passed' : ''}`}></div>
                    <div className="scroll-label">{section === 'top' ? 'Top' : section.charAt(0).toUpperCase() + section.slice(1).replace('-', ' ')}</div>
                </div>
            ))}
        </div>
    );
}
