import React from 'react';

import photo from "../assets/avatar.jpeg"

function HomePage() {
    return (
        <section id="home-page">
            <div className="container">

                <div className="row my-5">
                    <div className="col-md-5" align="center"> 
                        <img className="avatar my-3" src={photo} alt="Logo" /> 
                        <h2 className="fw-bold"> Artem Lukoianov </h2>
                        <h4> <a className="link-primary text-decoration-none" href="https://www.mit.edu" target="_blank" rel="noopener noreferrer"> Massachusets Institute of Technology </a> </h4>
                        <h4 className="text-muted"> PhD Candidate </h4>
                    </div>
                    <div className="col-md-7">
                        <h2 className="text-muted fw-bold"> About me </h2>
                        <p>  Hello, thank you for passing by! My name is Artem and right now I am either working in my lab, traveling, sailing, skate/snow/wind/surf-boarding, or doing something else that I might have picked up since the last update of this page. 
                        Even though research is only one out of many things I love to do - it is an important part of my life.
                        </p>
                        <p>In my Ph.D., I am working on solving various problems of Computer Vision, Computer Graphics, Neural Signal Representations, and Equivariant Learning.
                        I am lucky to be advised by <a className="link-primary text-decoration-none" href="https://people.csail.mit.edu/jsolomon/" target="_blank" rel="noopener noreferrer"> Prof. Justin Solomon </a> and
                        closely collaborate with <a className="link-primary text-decoration-none" href="https://www.vincentsitzmann.com" target="_blank" rel="noopener noreferrer"> Prof. Vincent Sitzmann </a>.
                        Zooming out, I am interested in general Artificial Intelligence and especially how its development affects our society short and long term.
                        </p>

                        <p className="pt-2" align="right"> arteml (at) mit (dot) edu</p>

                        <h1 className="pt-5" align="center">
                            <a className="m-4" href="https://scholar.google.com/citations?user=A9tNPiQAAAAJ&hl=en&oi=ao" target="_blank" rel="noopener noreferrer"> 
                                <span className="ai ai-google-scholar"></span>  
                            </a>
                            <a className="m-4" href="https://www.linkedin.com/in/artem-lukoianov/" target="_blank" rel="noopener noreferrer"> 
                                <span className="bi-linkedin"></span>
                            </a>
                            <a className="m-4" href="https://instagram.com/kandibober_lukoianov?igshid=YmMyMTA2M2Y=" target="_blank" rel="noopener noreferrer"> 
                                <span className="bi-instagram"></span>  
                            </a>
                        </h1>
                    </div>
                </div>

            </div>
        </section>
);
}

export default HomePage;