import React from 'react';

function Header() {
    return (
    <header className="sticky-top">
      <nav className="navbar px-4 navbar-expand-md navbar-light bg-light">
        <div className="container-xl text-dark fs-5">

            <div className="me-auto">
                <h4><span  role="img" aria-label="Emoji's about me"> 🤓⛵🏂🏔</span></h4>
            </div>
    
            <div className="ms-auto">
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link active" href="/#">Home</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://scholar.google.com/citations?user=A9tNPiQAAAAJ&hl=en&oi=ao">Publications</a>
                        </li>
                        {/* <li className="nav-item">
                            <a className="nav-link disabled" href="/#">Experience</a>
                        </li> */}
                        <li className="nav-item">
                            <a className="nav-link" href="/resume" target="_blank" rel="noopener noreferrer">CV</a>
                        </li>
                    </ul>
                </div>
            </div>

            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

        </div>
      </nav>

    </header>
);
}

export default Header;